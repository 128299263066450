import { template as template_3c45b8335f1442e3a2dd571cdc8a0a13 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3c45b8335f1442e3a2dd571cdc8a0a13(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
